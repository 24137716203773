<template>
  <v-data-table :headers="headers" :items="plcs" :pagination.sync="pagination" item-key="id"
    class="plcTable"
    :custom-filter="filteredPlcs"
    :search="''"
    :hide-actions="plcs.length <= pagination.rowsPerPage"
  >
    <template v-slot:headers="props">
      <tr class="table-header-row">
        <th v-for="header in props.headers" :key="header.value" 
          :class="[
            'column', 
            header.align === 'center' ? 'text-xs-center' : 'text-xs-left',
            header.sortable !== false ? 'sortable' : '',
            pagination.descending ? 'desc' : 'asc', 
            header.value === pagination.sortBy ? 'active' : ''
          ]"
          :style="{ 
            width: header.width,
          }"
          :title="header.text"
        >
          <div class="table-header" @click="changeSort(header)">
            {{ header.text }}
            <v-icon v-if="header.sortable !== false" small>arrow_upward</v-icon>
          </div>
          <table-filter-auto-complete-multi-select v-if="header.type === 'autocomplete'"
            :options="header.options"
            :values="header.selectedValues"
            :placeholder="header.placeholder"
            @selected="header.selected"
            @cleared="header.cleared"
          />
          <table-filter-text-search v-if="header.type === 'textsearch'"
            :value="header.search"
            :placeholder="header.placeholder"
            @changed="header.changed"
          />
          <table-filter-multi-select v-if="header.type === 'multiselect'"
            :options="header.options"
            :values="header.selectedValues"
            :placeholder="header.placeholder"
            @selected="header.selected"
            @cleared="header.cleared"
          />
        </th>
      </tr>
    </template>
    <template v-slot:items="props">
      <tr :key="props.item.id" :class="{ 'selected': props.item.id === selectedPlcId}" @click="selected(props.item.id)">
        <td :title="props.item.projectName">{{ props.item.projectName }}</td>
        <td :title="props.item.name">{{ props.item.name }}</td>
        <td :title="props.item.plcType">{{ props.item.plcType }}</td>
        <td :title="props.item.serialNumber">{{ props.item.serialNumber }}</td>
        <td>
          <plc-table-version :plc="props.item" @update="updateEcc"/>
        </td>
        <td class="no-text">
          <plc-table-status :plc="props.item" @selected="openPlcConnectionHistory"/>
        </td>
        <td class="no-text">
          <plc-table-alarms :plc="props.item" @selected="openAlarmLog"/>
        </td>
        <td class="no-text centered">
          <plc-table-actions :plc="props.item"
            @migrateEcc="migrateEcc"
            @restartEcc="restartEcc"
            @restartPlc="restartPlc"
            @updateInTestEcc="updateInTestEcc"
            @resetPlcConfigurationRelease="resetPlcConfigurationRelease"
            @updateTrumpfInverterFirmware="updateTrumpfInverterFirmware"
            @openAuditLog="openAuditLog"
            @openEcoLogTool="openEcoLogTool"
            @showPlcSystemInformation="showPlcSystemInformation"
            @collectSupportData = "collectSupportData"
          />
        </td>
      </tr>
    </template>
  </v-data-table>
</template>

<script lang="ts">
import { Component, Emit, Vue } from 'vue-property-decorator'
import { namespace } from 'vuex-class'

import PlcTableActions from './PlcTableActions.vue'
import PlcTableAlarms from './PlcTableAlarms.vue'
import PlcTableStatus from './PlcTableStatus.vue'
import PlcTableVersion from './PlcTableVersion.vue'

import TableFilterAutoCompleteMultiSelect from '../../../../ui/TableFilterAutoCompleteMultiSelect.vue'
import TableFilterMultiSelect from '../../../../ui/TableFilterMultiSelect.vue'
import TableFilterTextSearch from '../../../../ui/TableFilterTextSearch.vue'

import { PlcTableFilterSettings, PlcViewModel } from '../../../../../store/modules/selectPlcUi/models'

import { DropdownOption } from '@ecocoach/shared-components/src'

const App = namespace('app')
const Resource = namespace('resource')
const SelectPlcUi = namespace('selectPlcUi')

@Component({
  components: {
    PlcTableActions,
    PlcTableAlarms,
    PlcTableStatus,
    PlcTableVersion,
    TableFilterAutoCompleteMultiSelect,
    TableFilterMultiSelect,
    TableFilterTextSearch,
  },
})
export default class PlcTable extends Vue {
  @SelectPlcUi.Getter public isInteracted: boolean
  @SelectPlcUi.Getter public filterSettings: PlcTableFilterSettings
  @SelectPlcUi.Getter public plcs: PlcViewModel[]
  @SelectPlcUi.Getter public projects: DropdownOption[]
  @SelectPlcUi.Getter public plcTypes: DropdownOption[]
  @SelectPlcUi.Getter public versions: DropdownOption[]
  @SelectPlcUi.Getter public statuses: DropdownOption[]
  @SelectPlcUi.Getter public alarms: DropdownOption[]
  @SelectPlcUi.Getter public filteredPlcs: (plcs: PlcViewModel[]) => PlcViewModel[]
  @SelectPlcUi.Action public updateFilterSettings: (payload: { property: string, value: any }) => Promise<void>
  @App.Getter public selectedPlcId: string
  @Resource.Getter public dictionary

  public pagination = {
    sortBy: 'projectName',
    descending: false,
    rowsPerPage: 50,
  }

  @Emit('selected')
  public selected(plcId: string) {
    return plcId
  }

  @Emit('openAlarmLog')
  public openAlarmLog(plcId: string) {
    return plcId
  }

  @Emit('openPlcConnectionHistory')
  public openPlcConnectionHistory(plcId: string) {
    return plcId
  }

  @Emit('migrateEcc')
  public migrateEcc(plcId: string){
    return plcId
  }

  @Emit('restartEcc')
  public restartEcc(plcId: string) {
    return plcId
  }

  @Emit('restartPlc')
  public restartPlc(plcId: string) {
    return plcId
  }

  @Emit('updateEcc')
  public updateEcc(plcId: string) {
    return plcId
  }

  @Emit('updateInTestEcc')
  public updateInTestEcc(plcId: string) {
    return plcId
  }

  @Emit('resetPlcConfigurationRelease')
  public resetPlcConfigurationRelease(plcId: string) {
    return plcId
  }

  @Emit('updateTrumpfInverterFirmware')
  public updateTrumpfInverterFirmware(plcId: string) {
    return plcId
  }

  @Emit('openAuditLog')
  public openAuditLog(plcId: string) {
    return plcId
  }

  @Emit('openEcoLogTool')
  public openEcoLogTool(plcId: string) {
    return plcId
  }

  @Emit('showPlcSystemInformation')
  public showPlcSystemInformation(plc: PlcViewModel) {
    return plc
  }

  @Emit('collectSupportData')
  public collectSupportData(plc: PlcViewModel) {
    return plc
  }

  public changeSort(column) {
    if (column.sortable === false) {
      return
    }
    if (this.pagination.sortBy === column.value) {
      this.pagination.descending = !this.pagination.descending
    } else {
      this.pagination.sortBy = column.value
      this.pagination.descending = false
    }
  }

  public get headers() {
    return [
      { 
        text: this.dictionary('select.plc.project'),
        value: 'projectName',
        type: 'autocomplete',
        options: this.projects,
        selectedValues: this.filterSettings.projectIds,
        placeholder: this.dictionary('common.filter'),
        selected: (values: string[]) => this.updateFilterSettings({ 
          property: 'projectIds',
          value: values,
        }),
        cleared: () => this.updateFilterSettings({ 
          property: 'projectIds',
          value: [],
        }),
        width: '20%',
      },
      { 
        text: this.dictionary('select.plc.name'),
        value: 'name',
        type: 'textsearch',
        search: this.filterSettings.plcName,
        placeholder: this.dictionary('common.search'),
        changed: (searchString: string) => this.updateFilterSettings({ 
          property: 'plcName',
          value: searchString,
        }),
        width: '20%',
      },
      { 
        text: this.dictionary('select.plc.type'),
        value: 'plcType',
        type: 'multiselect',
        options: this.plcTypes,
        selectedValues: this.filterSettings.plcTypes,
        placeholder: this.dictionary('common.filter'),
        selected: (values: string[]) => this.updateFilterSettings({ 
          property: 'plcTypes',
          value: values,
        }),
        cleared: () => this.updateFilterSettings({ 
          property: 'plcTypes',
          value: [],
        }),
        width: '15%',
      },
      { 
        text: this.dictionary('plcSystemInformation.serialNumber'),
        value: 'serialNumber',
        type: 'textsearch',
        search: this.filterSettings.serialNumber,
        placeholder: this.dictionary('common.search'),
        changed: (searchString: string) => this.updateFilterSettings({ 
          property: 'serialNumber',
          value: searchString,
        }),
        width: '10%',
      },
      { 
        text: this.dictionary('select.plc.version'),
        value: 'ecoCloudConnectorVersion',
        type: 'multiselect',
        options: this.versions,
        selectedValues: this.filterSettings.versions,
        placeholder: this.dictionary('common.filter'),
        selected: (values: string[]) => this.updateFilterSettings({ 
          property: 'versions',
          value: values,
        }),
        cleared: () => this.updateFilterSettings({ 
          property: 'versions',
          value: [],
        }),
        width: '15%',
      },
      { 
        text: this.dictionary('select.plc.state'),
        value: 'status',
        type: 'multiselect',
        options: this.statuses,
        selectedValues: this.filterSettings.statuses,
        placeholder: this.dictionary('common.filter'),
        selected: (values: string[]) => this.updateFilterSettings({ 
          property: 'statuses',
          value: values,
        }),
        cleared: () => this.updateFilterSettings({ 
          property: 'statuses',
          value: [],
        }),
        width: '8%',
      },
      { 
        text: this.dictionary('select.plc.alarm'),
        value: 'alarms',
        type: 'multiselect',
        options: this.alarms,
        selectedValues: this.filterSettings.alarms,
        placeholder: this.dictionary('common.filter'),
        selected: (values: string[]) => this.updateFilterSettings({ 
          property: 'alarms',
          value: values,
        }),
        cleared: () => this.updateFilterSettings({ 
          property: 'alarms',
          value: [],
        }),
        width: '8%',
      },
      { 
        text: this.dictionary('navigation.more'),
        value: 'id',
        align: 'center',
        sortable: false,
        width: '5%',
      },
    ]
  }
}
</script>
<style lang="scss" scoped>
th, td {
  padding: 0px 15px !important;
  overflow: hidden;
  text-overflow: ellipsis;
  &.no-text {
    text-overflow: clip;
  }
  &.centered {
    display: flex;
    justify-content: center;
  }
}
thead tr {
  border-bottom: 1px solid white!important;
}
th:first-child, td:first-child {
  padding-left: 0px !important;
}
.table-header-row {
  vertical-align: top;
}
.table-header {
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: bold;
  text-transform: uppercase;
}
tr.selected {
  background: #c2043e;
}
</style>
<style lang="css">
.plcTable .theme--dark.v-table {
  background-color: transparent !important;
  table-layout: fixed;
  width: 100%;
  min-width: 1000px;
}
.plcTable .v-datatable__actions {
  background-color: transparent !important;
}
.plcTable .autocomplete-input input{
  cursor: pointer !important;
}

</style>