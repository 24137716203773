import { WizardMutation } from '@/store/modules/wizard/types'
import { RootState } from '@/store/types'
import { ArchitectureType, PlcOperationState } from '@ecocoach/domain-store-modules/src/common'
import { PlcModel } from '@ecocoach/domain-store-modules/src/plc/models'
import { PlcAction, PlcMutation } from '@ecocoach/domain-store-modules/src/plc/types'
import { PlcOperationAction } from '@ecocoach/domain-store-modules/src/plcOperation/types'
import AppDataStorageService from '@ecocoach/domain-store-modules/src/services/appdatastorage.service'
import { intersectArrays, sortedBy } from '@ecocoach/domain-store-modules/src/utils'
import { DropdownOption } from '@ecocoach/shared-components/src'
import { ActionTree } from 'vuex'
import { latestInTestEccRelease } from './helpers'
import { PlcSupportData, PlcTableFilterSettings, PlcTableFilterSettingsStorageKey } from './models'
import { SelectPlcUiAction, SelectPlcUiGetter, SelectPlcUiMutation, SelectPlcUiState } from './types'

export const actions: ActionTree<SelectPlcUiState, RootState> = {
  async [SelectPlcUiAction.initialize] ({ dispatch, rootState }): Promise<void> {
    await Promise.all([
      dispatch(`plcOperation/${PlcOperationAction.loadComponentFirmwareReleases}`, null, { root: true }),
      dispatch(`plc/${PlcAction.loadPlcs}`, null, { root: true }),
    ])
    const plcIds = rootState.plc.plcs.map(p => p.id)
    dispatch(`plc/${PlcAction.startForPlcs}`, plcIds, { root: true })
  },
  async [SelectPlcUiAction.migratePlcToEcoCloudConnector] ({ commit, dispatch, rootState }, plcId: string): Promise<void> {
    try {
      commit(SelectPlcUiMutation.setInteracted, true)
      await dispatch(`plcOperation/${PlcOperationAction.migratePlcToEcoCloudConnector}`, plcId, { root: true })
      const plc = rootState.plc.plcs.find(p => p.id === plcId)!
      commit(`plc/${PlcMutation.upsertPlc}`, {
        ...plc,
        timeStamp: new Date().toISOString(),
        plcOperationState: PlcOperationState.Updating,
      } as PlcModel, { root: true })
    } finally {
      commit(SelectPlcUiMutation.setInteracted, false)
    }
  },
  async [SelectPlcUiAction.updateToLatestEcoCloudConnector] ({ commit, dispatch, rootState }, payload: {plcId: string, forceUpdate: boolean})
  : Promise<void> {
    try {
      commit(SelectPlcUiMutation.setInteracted, true)
      await dispatch(`plcOperation/${PlcOperationAction.updateToLatestEcoCloudConnector}`, payload, { root: true })
      const plc = rootState.plc.plcs.find(p => p.id === payload.plcId)!
      commit(`plc/${PlcMutation.upsertPlc}`, {
        ...plc,
        timeStamp: new Date().toISOString(),
        plcOperationState: PlcOperationState.Updating,
      } as PlcModel, { root: true })
      commit(SelectPlcUiMutation.setNumberOfMessagesInQueue, 0)
    } catch (error) {
      if ((error as any).response?.data?.exceptionType === 'NumberOfMessagesInQueueException') {
        commit(SelectPlcUiMutation.setNumberOfMessagesInQueue, (error as any).response.data.numberOfMessagesInQueue)
        commit(`wizard/${WizardMutation.hideToast}`, undefined, {root: true})
      }
    } finally {
      commit(SelectPlcUiMutation.setInteracted, false)
    }
  },
  async [SelectPlcUiAction.updateToLatestInTestEcoCloudConnectorVersion]({commit, dispatch, rootState},
    payload: { plcId: string, forceUpdate: boolean }): Promise<void> {
    try {
      commit(SelectPlcUiMutation.setInteracted, true)
      const plc = rootState.plc.plcs.find(p => p.id === payload.plcId)!
      const versionId = latestInTestEccRelease(rootState.plcOperation.componentFirmwareReleases, plc.plcType)?.id
      await dispatch(`plcOperation/${PlcOperationAction.updateToEcoCloudConnectorVersion}`, {
        plcId: plc.id,
        versionId,
        forceUpdate: payload.forceUpdate,
      }, { root: true })
      commit(`plc/${PlcMutation.upsertPlc}`, {
        ...plc,
        timeStamp: new Date().toISOString(),
        plcOperationState: PlcOperationState.Updating,
      } as PlcModel, { root: true })
      commit(SelectPlcUiMutation.setNumberOfMessagesInQueue, 0)
    } catch (error) {
      if ((error as any).response?.data?.exceptionType === 'NumberOfMessagesInQueueException') {
        commit(SelectPlcUiMutation.setNumberOfMessagesInQueue, (error as any).response.data.numberOfMessagesInQueue)
        commit(`wizard/${WizardMutation.hideToast}`, undefined, {root: true})
      }
    } finally {
      commit(SelectPlcUiMutation.setInteracted, false)
    }
  },
  async [SelectPlcUiAction.updateTrumpfInverterFirmware] ({ commit, dispatch }, payload: { plcId: string, versionId: string, ipAddress: string }):
    Promise<void> {
    try {
      commit(SelectPlcUiMutation.setInteracted, true)
      await dispatch(`plcOperation/${PlcOperationAction.deployComponentFirmware}`, payload, { root: true })
    } finally {
      commit(SelectPlcUiMutation.setInteracted, false)
    }
  },
  async [SelectPlcUiAction.restartEcoCloudConnector] ({ commit, dispatch }, plcId: string): Promise<void> {
    try {
      commit(SelectPlcUiMutation.setInteracted, true)
      await dispatch(`plcOperation/${PlcOperationAction.restartEcoCloudConnector}`, plcId, { root: true })
    } finally {
      commit(SelectPlcUiMutation.setInteracted, false)
    }
  },
  async [SelectPlcUiAction.restartPlc] ({ commit, dispatch }, plcId: string): Promise<void> {
    try {
      commit(SelectPlcUiMutation.setInteracted, true)
      await dispatch(`plcOperation/${PlcOperationAction.restartPlc}`, plcId, { root: true })
    } finally {
      commit(SelectPlcUiMutation.setInteracted, false)
    }
  },
  async [SelectPlcUiAction.resetPlcConfigurationRelease] ({ commit, dispatch }, plcId: string): Promise<void> {
    try {
      commit(SelectPlcUiMutation.setInteracted, true)
      await dispatch(`plcOperation/${PlcOperationAction.resetPlcConfigurationRelease}`, plcId, { root: true })
    } finally {
      commit(SelectPlcUiMutation.setInteracted, false)
    }
  },
  async [SelectPlcUiAction.collectSupportData] ({ commit, dispatch, rootState }, plcId: string): Promise<void> {
    try {
      commit(SelectPlcUiMutation.setInteracted, true)
      await dispatch(`plcOperation/${PlcOperationAction.loadConfigurationByPlcId}`, { plcId, language: rootState.app.selectedLanguage }, { root:true })
      const usedFunctionBlockDefinitionIds = Array.from(new Set(rootState.plcOperation.devices.map(d => d.functionBlockDefinitionId)))
      const usedFunctionBlockDefinitions = rootState.systemConfiguration.functionBlocks.filter(fb => usedFunctionBlockDefinitionIds.includes(fb.id))
      const architectureType = usedFunctionBlockDefinitions.some(fb => fb.architectureType === ArchitectureType.ObjectOriented) ? ArchitectureType.ObjectOriented : (usedFunctionBlockDefinitions.some(fb => fb.architectureType === ArchitectureType.Classic) ? ArchitectureType.Classic : null)
      const supportData = {
        plcId,
        architectureType,
        functionBlockDefinitions: sortedBy(usedFunctionBlockDefinitions.map(fb => {
          return {
            internalName: fb.internalName,
            version: fb.version,
          }
        }), fb => fb.internalName),
      } as PlcSupportData
      commit(SelectPlcUiMutation.setSupportData, supportData)
    } finally {
      commit(SelectPlcUiMutation.setInteracted, false)
    }
  },
  async [SelectPlcUiAction.loadFilterSettings] ({ state, getters }): Promise<void> {
    const loadedSettings = await AppDataStorageService.getObject<PlcTableFilterSettings>(PlcTableFilterSettingsStorageKey)
    const validProjectIds = (getters[SelectPlcUiGetter.projects] as DropdownOption[]).map(o => o.id as string)
    const validPlcTypes = (getters[SelectPlcUiGetter.plcTypes] as DropdownOption[]).map(o => o.id as string)
    const validVersions = (getters[SelectPlcUiGetter.versions] as DropdownOption[]).map(o => o.id as string)
    const validStatuses = (getters[SelectPlcUiGetter.statuses] as DropdownOption[]).map(o => o.id as string)

    state.filterSettings.projectIds = intersectArrays(validProjectIds, loadedSettings?.projectIds ?? [])
    state.filterSettings.plcName = loadedSettings?.plcName ?? ''
    state.filterSettings.plcTypes = intersectArrays(validPlcTypes, loadedSettings?.plcTypes ?? [])
    state.filterSettings.versions = intersectArrays(validVersions, loadedSettings?.versions ?? [])
    state.filterSettings.serialNumber = loadedSettings?.serialNumber ?? ''
    state.filterSettings.statuses = intersectArrays(validStatuses, loadedSettings?.statuses ?? [])
    state.filterSettings.alarms = loadedSettings?.alarms ?? []
  },
  async [SelectPlcUiAction.updateFilterSettings] ({ state }, payload: { property: string, value: any }): Promise<void> {
    state.filterSettings[payload.property] = payload.value
    await AppDataStorageService.setObject(PlcTableFilterSettingsStorageKey, state.filterSettings)
  },
}
